@import '../../../styling/helpers';

.container {
    width: 100vw;
    height: 100%;
    transform: translate3d(0, 0, -1px);
    display: block;
    margin: 0 auto;
    max-width: 80em;
}
