@import '../../../styling/helpers';

@mixin center-content {
    display: flex;
    align-items: center;
}

.container {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;

    @media screen and (min-width: $bp_phone) {
        flex-direction: row;
    }

    .description-container {
        display: flex;
        flex: 2;
        align-self: flex-start;
    }

    .date {
        @include center-content();

        padding: 0.5rem 1rem;
        padding-left: 0;
        padding-right: 0.375rem;
        min-width: 3rem;
    }

    .logo {
        @include center-content();

        padding: 0.5rem 0;
    }

    .title {
        @include center-content();

        padding: 0.5rem 1rem;
        padding-left: 0.375rem;
    }

    .price-container {
        display: flex;
        flex: 2;
        justify-content: flex-end;
        align-self: flex-end;
    }

    .price {
        @include center-content();

        padding: 0.5rem 1rem;
        text-align: right;
    }

    .button {
        padding: 0.5rem 1rem;
        padding-right: 0;
    }
}
