@import '../../../styling/helpers';

.payment-header {
    border-bottom: 1px solid rgba($zst_gray_white, 0.2);
    background: linear-gradient(180deg, $zst_gray_darker 0%, $zst_gray_black 100%);

    > div {
        display: block;
        margin: 0 auto;
        padding: 0 16px;
        max-width: 76em;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 72px 0 56px;

            > h1 {
                text-align: center;
                margin-bottom: 56px;
            }

            > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: fit-content;

                > span {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    padding: 0 18px;
                    position: relative;
                    margin-bottom: 21px;
                    color: rgba($zst_white, 0.2);

                    &:not(:last-child) {
                        margin-right: 2px;
                    }

                    &:after {
                        position: absolute;
                        content: '';
                        bottom: -21px;
                        left: 0;
                        right: 0;
                        height: 4px;
                        background-color: rgba($zst_white, 0.2);
                    }

                    &[data-done=true] {
                        color: $zst_primary;
                    }

                    &[data-active=true] {
                        @include theme('color', 'text');
                    }

                    &[data-done=true]:after,
                    &[data-active=true]:after {
                        background-color: $zst_primary;
                    }
                }
            }
        }
    }
}
