@import '../../../styling/helpers';

.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.container {
    display: flex;
}

.label {
    @include theme('color', 'text');

    font-size: 16px;
    font-weight: normal;
    margin-left: 1rem;
    cursor: pointer;
}

.checkbox {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    appearance: none;
    outline: none;
    border: 2px solid transparent;
    border-radius: 4px;
    background: rgba($zst_gray_white, 0.2);
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
        border-radius: 4px;
        background: $zst_primary;
    }

    &:checked {
        &:before {
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
        }
    }

    &:hover,
    &:focus {
        border: 1px solid $zst_primary;

        &:checked {
            &:before {
                top: 3px;
                right: 3px;
                bottom: 3px;
                left: 3px;
            }
        }
    }
}

.errorMessage {
    color: $zst_error;
    font-size: 14px;
    margin-top: 0.8rem;
}

.error {
    border: 1px solid $zst_error;

    &:hover {
        border: 1px solid $zst_error;
    }

    &:focus {
        border: 1px solid $zst_error;
    }
}
