@function z-index($key) {
    @return map-get($z-index, $key);
}

@mixin z-index($key) {
    z-index: z-index($key);
}

// Theme helpers
@mixin theme($properties, $name, $prefix: '', $suffix: '') {
    $value: map-get($theme, $name);

    @if $value {
        @if type-of($value) == 'map' {
            @each $theme-name, $theme-value in $value {
                @if $theme-value {
                    @each $property in $properties {
                        :global([data-theme='#{$theme-name}']) & {
                            #{$property}: #{$prefix} $theme-value #{$suffix};
                        }
                    }
                }

                @else {
                    @warn 'The `#{$theme-name}` value is not declared in the `#{$name}` theme settings';
                }
            }
        }

        @else {
            @each $property in $properties {
                #{$property}: #{$prefix} $value #{$suffix};
            }
        }
    }

    @else {
        @warn 'The given `#{$name}` is missing in the theme settings';
    }
}
