@import '../../../styling/helpers';

.color-gray-black {
    path {
        fill: $zst_gray_black;
    }
}

.color-gray-darker {
    path {
        fill: $zst_gray_darker;
    }
}

.color-gray-dark {
    path {
        fill: $zst_gray_dark;
    }
}

.color-gray-medium {
    path {
        fill: $zst_gray_medium;
    }
}

.color-gray {
    path {
        fill: $zst_gray;
    }
}

.color-gray-light {
    path {
        fill: $zst_gray_light;
    }
}

.color-gray-white {
    path {
        fill: $zst_gray_white;
    }
}

.color-primary {
    path {
        fill: $zst_primary;
    }
}

.color-secondary {
    path {
        fill: $zst_secondary;
    }
}

.color-white {
    path {
        fill: $zst_white;
    }
}

.color-black {
    path {
        fill: $zst_black;
    }
}

.color-bg {
    path {
        fill: $zst_bg;
    }
}
