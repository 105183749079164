@import '../../../styling/helpers';

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 30rem;

    background-color: $zst_gray_medium;
    padding: 2rem;
    border-radius: 0.5rem;
}

.modal-content {
    padding: 2rem 0;
}

.modal-button {
    width: 100%;
    text-align: right;
}

.close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}

.modal-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.button-spacing {
    margin-right: auto;
}
