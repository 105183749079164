.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.title {
    font-weight: bold;
    font-size: 1.25rem;
}

.sub-title {
    font-size: 1rem;
}
