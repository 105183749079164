@import '../../../styling/helpers';

.root {
    @include theme('color', 'text');

    font-weight: bold;
    font-size: 42px;
}

.bold {
    font-weight: bold;
}

.size-smaller {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.size-small {
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
}

.size-medium {
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.size-large {
    font-size: 3rem;
    margin-bottom: 1.2rem;
}
