.close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}

.modal-content {
    padding: 2rem 0;
}

.modal-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.modal-button {
    width: 100%;
    text-align: right;
}
