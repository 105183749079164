@import '../../../styling/helpers';

.container {
    display: flex;
    align-items: center;
    flex: 1;

    .title {
        padding: 0.5rem 1rem;
        padding-left: 0;
        flex: 1;
        display: flex;
    }

    .paid {
        padding: 0.5rem 1rem;
    }

    .link {
        padding: 0.5rem 1rem;
        padding-right: 0;
    }
}
