@import '../../../styling/helpers';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    height: 3.5rem;
    border: 1px solid rgba($zst_gray_white, 0.2);
    border-radius: 4px;
    align-items: center;

    &:hover {
        cursor: pointer;
        border: 1px solid $zst_primary;
    }

    &.active {
        border: 1px solid $zst_primary;
    }
}

.logo {
    margin-bottom: 0.6rem;
    width: calc(8.3rem / 1.6);
}

.name {
    text-align: center;
}
