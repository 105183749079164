// responsive breakpoints
$bp_phone: 414px; // mobile
$bp_tablet: 768px; // tablet
$bp_tablet_big: 1024px; // tablet-big
$bp_laptop: 1440px; // desktop
$bp_monitor: 1920px; // monitor

$z-index: (
    modal: 1000,
);

// ZST colors
// semantic colors
$zst_gray_black: #191a1a;
$zst_gray_darker: #1e2020;
$zst_gray_dark: #262727;
$zst_gray_medium: #5e6062;
$zst_gray: #828381;
$zst_gray_light: #b6b6ba;
$zst_gray_white: #f1f2f3;

// primary colors
$zst_primary: #f48c01;
$zst_secondary: $zst_gray_medium;
$zst_error: #dc0000;

// neutral colors
$zst_white: $zst_gray_white;
$zst_black: #000;
$zst_bg: $zst_gray_dark;

$colors: (
    primary: $zst_primary,
    secondary: $zst_secondary,
);

$theme: (
    // Example
    font_body_1: 400 3.6rem/1.4 'interstate',
    background: $zst_bg,
    text: $zst_white
);
