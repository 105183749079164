@import '../../../styling/helpers';

.root {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    padding: 12px 16px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid $zst_primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    will-change: color, border-color, background-color;
    background-color: $zst_primary;
    transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out,
        0.2s background-color ease-in-out;
    color: $zst_black;

    &:hover {
        border-color: darken($zst_primary, 5%);
        background-color: darken($zst_primary, 5%);
    }

    &:disabled {
        cursor: default;
        border-color: darken($zst_gray, 5%);
        background-color: darken($zst_gray, 5%);
    }

    > svg:first-child {
        margin-right: 8px;
    }
}

.size-smaller {
    border-radius: 4px;
    padding: 8px;
}

.size-small {
    border-radius: 4px;
    padding: 11px 16px;
}

.size-medium {
    border-radius: 6px;
    padding: 15px 24px;
}

.color-secondary {
    @include theme('color', 'text');

    background-color: $zst_secondary;
    border: 1px solid $zst_secondary;

    &:hover {
        border-color: darken($zst_secondary, 5%);
        background-color: darken($zst_secondary, 5%);
    }
}

.color-black {
    @include theme('color', 'text');

    background-color: $zst_black;
    border: 1px solid $zst_black;

    &:hover {
        border-color: lighten($zst_black, 5%);
        background-color: lighten($zst_black, 5%);
    }
}

.color-white {
    color: $zst_primary;
    background-color: $zst_white;
    border: 1px solid $zst_white;

    &:hover {
        border-color: lighten($zst_white, 10%);
        background-color: lighten($zst_white, 10%);
    }
}

.color-transparent {
    color: $zst_black;
    background-color: transparent;
    border: 1px solid $zst_black;

    &:hover {
        color: $zst_black;
        background-color: $zst_white;
        border-color: $zst_black;

        svg,
        svg * {
            fill: $zst_black;
        }
    }
}
