@import '../../../styling/helpers';

header.header {
    width: 100%;
    z-index: 9999;
    position: relative;

    .header-top {
        position: relative;
        background-image: url('/Base_Orange.png');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 5;

        @media screen and (max-width: 53.99em) {
            background-image: url('/Base_Orange_Mobile.png');
        }

        > div {
            display: block;
            margin: 0 auto;
            padding: 0 16px;
            max-width: 76em;

            @media screen and (min-width: 40em) {
                padding: 0 32px;
            }

            @media screen and (max-width: 53.99em) {
                padding: 16px 32px;
            }

            @media screen and (max-width: 400px) {
                padding: 1rem;
            }

            > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 32px;

                @media screen and (min-width: 53.99em) {
                    height: 88px;
                }

                .header-logo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    > a {
                        display: flex;

                        > svg {
                            fill: $zst_white;

                            @media screen and (min-width: 53.99em) {
                                width: 129px;
                                height: 48px;
                            }
                        }

                        // &:last-of-type {
                        //     @include theme('color', 'text');

                        //     flex-direction: column;
                        //     margin-left: 24px;
                        //     font-size: .875rem;
                        //     font-weight: 500;
                        //     line-height: 1.3;
                        //     text-decoration: none;

                        //     @media screen and (max-width: 53.99em) {
                        //         display: none;
                        //     }

                        //     > span {
                        //         margin: 0;

                        //         &:first-of-type {
                        //             font-weight: 700;
                        //             font-size: 1.0625rem;
                        //         }
                        //     }
                        // }
                    }
                }

                .header-buttons {
                    display: flex;

                    button {
                        .user-fix {
                            margin-bottom: -3px;
                        }

                        &:not(:last-child) {
                            margin-right: 1.6rem;
                        }

                        @media screen and (max-width: 53.99em) {
                            font-size: 0.75rem;

                            & svg {
                                display: none;
                            }

                            &:not(:last-child) {
                                margin-right: 0.25rem;
                            }
                        }

                        @media screen and (max-width: 400px) {
                            padding: 0.5rem;

                            &:not(:last-child) {
                                margin-right: 0.25rem;
                            }
                        }
                    }

                    .popover {
                        display: none;

                        @media screen and (max-width: 53.99em) {
                            display: block;
                            position: absolute;
                            top: 4rem;
                            right: 2rem;
                            padding: 1rem 1.5rem;
                            min-width: 200px;
                            border-radius: 5px;
                            background-color: $zst_black;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;

                            &[data-open='false'] {
                                display: none;
                            }

                            > p {
                                cursor: pointer;
                                font-size: 16px;
                                // will-change: color;
                                // transition: color .2s ease;
                                letter-spacing: 0.01rem;
                                line-height: 35px;
                                font-weight: 300;

                                &:hover,
                                &:focus,
                                &:active {
                                    color: $zst_primary;
                                }
                            }
                        }
                    }

                    .desktop {
                        display: inline-flex;

                        @media screen and (max-width: 53.99em) {
                            display: none;
                        }
                    }

                    .mobile {
                        display: none;

                        @media screen and (max-width: 53.99em) {
                            display: inline-flex;
                        }
                    }
                }
            }
        }
    }

    .navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $zst_black;

        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex: 1;
            padding: 0 16px;
            max-width: 76em;

            @media screen and (min-width: 40em) {
                padding: 0 32px;
            }

            @media screen and (max-width: 53.99em) {
                padding: 0;
            }

            > .navigation-logo {
                position: relative;
                will-change: width;
                width: 0;
                transition: width 0.2s ease-in-out;

                @media screen and (max-width: 53.99em) {
                    display: none;
                }

                &[data-sticky='true'] {
                    width: 110px; // 86px width + 24px margin

                    > svg {
                        opacity: 1;
                    }
                }

                > svg {
                    position: absolute;
                    transform: translate3d(0, -50%, 0);
                    will-change: opacity;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }
            }

            > .navigation-link {
                @include theme('color', 'text');

                font-size: 16px;
                font-weight: 700;
                line-height: 32px;
                text-decoration: none;
                position: relative;
                letter-spacing: 0.01rem;

                &:hover > .active {
                    opacity: 1;
                }

                &:not(:last-child) {
                    margin-right: 24px;
                }

                @media screen and (max-width: 53.99em) {
                    flex: 1 1 auto;
                    margin: 0 !important;
                    line-height: 40px;
                    font-weight: 300;
                    text-align: center;

                    &:hover,
                    &:hover,
                    &:active,
                    &[data-active='true'] {
                        > p {
                            color: $zst_primary;
                        }
                    }

                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8),
                    &:nth-child(9) {
                        display: none;
                    }

                    &:not(:last-of-type):after {
                        content: '';
                        position: absolute;
                        top: 8px;
                        right: 0;
                        width: 1px;
                        height: calc(100% - 16px);
                        background-color: $zst_gray_dark;
                    }
                }

                > .active {
                    position: absolute;
                    left: -4px;
                    right: 0;
                    bottom: 0;
                    width: calc(100% + 8px);
                    border-bottom: 2px solid $zst_primary;
                    will-change: opacity;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;

                    @media screen and (max-width: 53.99em) {
                        display: none;
                    }

                    &[data-active='true'] {
                        opacity: 1;
                    }
                }

                > p {
                    margin: 16px 0;

                    @media screen and (max-width: 53.99em) {
                        margin: 0;
                    }

                    > svg {
                        > path {
                            fill: $zst_white;
                        }
                    }
                }
            }
        }
    }
}
