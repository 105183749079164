@import '../../../styling/helpers';

.terms {
    display: flex;
    flex-direction: row;
    margin: 20px 0 10px;
    user-select: none;

    > label > a {
        color: $zst_primary;
        text-decoration: underline;
    }
}
