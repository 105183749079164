@import '../../../styling/helpers';

.container {
    margin: 2rem;

    > div {
        margin-bottom: 2rem;

        > div > div > div {
            padding: 0.5rem;
            box-sizing: border-box;

            > div > div {
                outline: none;
            }
        }
    }
}
