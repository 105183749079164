@import '../../../styling/helpers';

.container {
    padding: 2rem;
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr;

    @media screen and (min-width: $bp_tablet_big) {
        grid-gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
    }

    .form {
        width: 100%;
        margin: 0 auto;

        @media screen and (min-width: $bp_tablet) {
            max-width: 475px;
        }
    }
}

.forgot {
    margin-top: 1rem;
}
