@import '../../../styling/helpers';

.container {
    width: 100%;
    height: 410px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    > .gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: linear-gradient(
            180deg,
            rgba($zst_gray_black, 0) 0%,
            rgba($zst_gray_black, 0.4) 75%,
            rgba($zst_gray_black, 0.8) 100%
        );
    }

    > .content {
        width: 100%;
        max-width: 76em;
        padding: 0 32px 2rem;

        > .title {
            font-weight: 700;
            font-size: 48px;
            line-height: 120%;
            color: $zst_gray_white;
        }

        > .subtitle {
            font-weight: 300;
            font-size: 24px;
            line-height: 120%;
            max-width: 30rem;
            color: $zst_gray_white;
            padding-left: 2px; // to compensate for title font size
        }
    }
}
