@import '../../../styling/helpers';

.pre-footer {
    background-image: url('/Background_Panels_Footer.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 40px 0 24px;
    transform: translate3d(0, 0, -1px);

    @media screen and (min-width: 30em) {
        padding: 96px 0 48px;
    }

    > div {
        display: block;
        margin: 0 auto;
        padding: 0 32px;
        max-width: 76em;

        > div {
            display: flex;
            flex-direction: row;
            justify-content: center;

            > div {
                position: relative;
                display: flex;
                flex-direction: column;
                padding: 32px 24px 24px;
                background: linear-gradient(180deg, $zst_gray_darker, $zst_gray_black);
                border: 1px solid rgba($zst_white, 0.2);
                border-radius: 4px;
                box-sizing: border-box;
                max-width: 592px;
                z-index: 1;

                @media screen and (min-width: 40em) {
                    padding: 40px 32px 48px;
                }

                > .pre-footer-heading {
                    display: flex;
                    margin-bottom: 16px;

                    > svg {
                        flex-shrink: 0;
                        margin-right: 1rem;
                    }

                    > p {
                        padding-top: 0.2rem;
                        font-size: 1.2rem;
                        margin-bottom: 0.6rem;
                        color: $zst_white;
                        font-weight: bold;
                    }
                }

                > p {
                    line-height: 1.8;
                    margin-bottom: 2rem;

                    @media screen and (min-width: 60em) {
                        max-width: 65%;
                    }
                }

                > .pre-footer-buttons {
                    width: 100%;

                    > form {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        width: 100%;

                        @media screen and (min-width: 40em) {
                            flex-direction: row;
                            align-self: flex-start;
                        }

                        > div {
                            flex: 1;
                            margin-right: 1rem;
                            position: relative;

                            > div > div {
                                // input error
                                margin: 0.5rem 0 -0.3rem;

                                @media screen and (min-width: 40em) {
                                    margin: 0.5rem 0 -1.8rem;
                                }
                            }
                        }

                        > button {
                            margin-bottom: 1rem;
                            padding: 1.05rem 1.5rem;
                            align-self: flex-start;

                            @media screen and (min-width: 40em) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

footer.footer {
    transform: translate3d(0, 0, -1px);

    nav > ul {
        > li {
            margin: 0;
            padding: 0;
            list-style: none;

            > a {
                color: $zst_gray_light;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    > .footer-top {
        background: linear-gradient(180deg, $zst_gray_darker, $zst_gray_black);

        > div {
            margin: 0 auto;
            max-width: 76em;
            padding: 0 32px;

            > div {
                display: flex;
                flex-direction: column;
                padding: 40px 0;

                @media screen and (min-width: 40em) {
                    flex-direction: row;
                }

                @media screen and (min-width: 76em) {
                    align-items: center;
                }

                > div {
                    display: flex;
                    flex-direction: column;

                    @media screen and (max-width: 39.99em) {
                        &:not(:last-child) {
                            margin-bottom: 32px;
                        }
                    }

                    @media screen and (min-width: 60em) {
                        &:nth-child(1) {
                            width: calc(30%);
                        }

                        &:nth-child(2) {
                            width: calc(30% + 16px);
                        }

                        &:nth-child(3) {
                            width: calc(30%);
                        }
                    }

                    > span {
                        // font-size: 16px;
                        font-weight: 700;
                        margin-bottom: 16px;
                    }

                    > div {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-top: -12px;
                        // max-width: 256px;

                        @media screen and (min-width: 40em) {
                            max-width: 280px;
                        }

                        > a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 12px 16px 8px 0;
                            border-radius: 4px;
                            cursor: pointer;
                            transition: all 0.2s ease;
                            background: transparent;
                            box-sizing: border-box;
                            line-height: 1.15;
                            font-size: 0.875rem;
                            color: $zst_gray_light;
                            text-decoration: none;

                            @media screen and (min-width: 30em) {
                                font-size: 1rem;
                            }

                            > svg {
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }
    }

    > .footer-main {
        background-color: $zst_gray_dark;

        > div {
            margin: 0 auto;
            max-width: 76em;
            padding: 0 16px;

            @media screen and (min-width: 40em) {
                padding: 0 32px;
            }

            > div {
                display: flex;
                flex-direction: column;
                padding: 40px 0 56px;

                @media screen and (min-width: 40em) {
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 72px 0 96px;
                }

                @media screen and (min-width: 60em) {
                    display: flex;
                    flex-direction: row;
                    width: calc(100% + 16px);
                    margin-left: -8px;
                }

                @media screen and (min-width: 40em) and (min-width: 60em) {
                    width: calc(100% + 32px);
                    margin-left: -16px;
                }

                > .footer-main-menu {
                    margin: 0 16px 30px;
                    box-sizing: border-box;
                    width: calc(100% - 32px);
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 40px;
                    grid-template-areas: 'a b';

                    @media screen and (min-width: 40em) {
                        margin: 0 16px;
                        width: calc(58.33333% - 32px);
                        grid-template-columns: 1fr 1fr;
                        grid-template-areas: 'a' 'b';
                    }

                    @media screen and (min-width: 60em) {
                        grid-template-areas: 'a b';
                        width: calc(58.33333% - 16px);
                        margin: 8px;
                        flex-shrink: 0;
                        flex-grow: 0;
                    }

                    @media screen and (min-width: 40em) and (min-width: 60em) {
                        width: calc(58.33333% - 32px);
                        margin: 0 16px;
                    }

                    > nav {
                        &:nth-child(2) {
                            grid-area: b;
                        }
                        &:nth-child(3) {
                            grid-area: c;
                        }
                        &:nth-child(4) {
                            grid-area: d;
                        }

                        .footerMainMenuTitle {
                            line-height: 1.2;
                            margin-bottom: 16px;
                            font-size: 1.2rem;
                            font-weight: bold;
                            color: $zst_white;
                        }

                        > ul {
                            margin: 0;
                            padding: 0;

                            > li {
                                margin: 0;
                                padding: 0;
                                list-style: none;

                                > a {
                                    line-height: 2;
                                }
                            }
                        }
                    }
                }

                > .footer-main-info {
                    margin: 0 16px;
                    width: calc(100% - 32px);

                    @media screen and (min-width: 40em) {
                        margin: 0 16px;
                        padding-left: 24px;
                        width: calc(58.33333% - 32px);
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-template-areas: 'a b c' 'a b d';
                    }

                    > h3 {
                        line-height: 1.2;
                        margin-bottom: 16px;

                        &:last-of-type {
                            margin-bottom: 24px;
                        }
                    }

                    > p {
                        line-height: 1.8;
                        margin-bottom: 16px;
                    }

                    > a {
                        display: inline-flex;
                        align-items: center;
                        padding: 0;
                        font-size: 1rem;
                        font-weight: 700;
                        line-height: 2;
                        text-decoration: none;
                        align-self: flex-start;
                        margin-bottom: 40px;
                        color: #f48c01;

                        > svg {
                            will-change: margin-left;
                            margin-left: 6px;
                            transition: margin-left 0.2s ease;
                        }

                        &:hover > svg {
                            margin-left: 12px;
                        }
                    }

                    > .footer-main-store {
                        > a {
                            margin: 0 16px 8px 0;
                        }
                    }
                }
            }
        }
    }

    > .footer-bottom {
        background: linear-gradient(180deg, $zst_gray_darker, $zst_gray_black);

        > div {
            display: block;
            margin: 0 auto;
            padding: 0 16px;
            max-width: 1280px;

            @media screen and (min-width: 40em) {
                padding: 0 32px;
            }

            > div {
                display: flex;
                flex-direction: column;
                padding: 32px 0;

                @media screen and (min-width: 30em) {
                    padding: 24px 0;
                }

                @media screen and (min-width: 76em) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                > nav > ul {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;

                    @media screen and (min-width: 30em) {
                        flex-direction: row;
                        flex-wrap: wrap;
                    }

                    > li {
                        margin: 0 48px 10px 0;
                        padding: 0;
                        list-style: none;

                        @media screen and (min-width: 715px) {
                            margin: 0 48px 0 0;
                        }

                        > a {
                            color: $zst_gray_light;
                            font-size: 0.875rem;
                            text-decoration: none;
                            line-height: 2;

                            @media screen and (min-width: 30em) {
                                font-size: 1rem;
                                line-height: 1.15;
                            }
                        }
                    }
                }

                > p {
                    margin-top: 24px;
                    font-size: 0.875rem;
                    line-height: 1.15;
                    opacity: 0.4;

                    @media screen and (min-width: 30em) {
                        font-size: 1rem;
                    }

                    @media screen and (min-width: 76em) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
