@import '../../../styling/helpers';

.logo {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
    display: block;
}

.subtitle {
    display: flex;
    margin-top: 0.5rem;
}

.time {
    margin-right: 0.8rem;
    color: $zst_gray;
}

.live {
    font-weight: bold;
    color: $zst_primary;
    margin-right: 0.5rem;
}

.subtitle-content {
    display: flex;
}

.date {
    margin-right: 1rem;
}
