@import '../../../styling/helpers';

.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.label {
    @include theme('color', 'text');

    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.errorMessage {
    color: $zst_error;
    font-size: 14px;
    margin-top: 0.8rem;
}

.root {
    @include theme('color', 'text');

    background-color: rgba($zst_gray_white, 0.2);
    border: 1px solid rgba($zst_gray_white, 0.2);
    border-radius: 6px;
    line-height: 1.15;
    font-size: 1rem;
    padding: 15px 16px;
    outline: none;

    &:hover:not(:disabled) {
        border: 1px solid $zst_primary;
    }

    &:focus {
        border: 1px solid $zst_primary;
    }

    &::placeholder {
        color: $zst_gray_light;
    }
}

.error {
    border: 1px solid $zst_error;

    // &:hover {
    //     border: 1px solid $zst_error;
    // }

    // &:focus {
    //     border: 1px solid $zst_error;
    // }
}
