@import '../../../styling/helpers';

.container {
    .icon {
        display: none;
        padding: 1rem;
        background-color: rgba($zst_gray_darker, 0.3);
        border-radius: 6px;
    }

    &:hover {
        cursor: pointer;

        .icon {
            display: block;
        }

        .imageContainer {
            border: 1px solid $zst_primary;
        }
    }
}

.imageContainer {
    width: 100%;
    padding-bottom: 56%;
    position: relative;
    border: 1px solid rgba($zst_gray_white, 0.2);
    border-radius: 4px;
}

.image {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
}

.iconContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.duration {
    position: absolute;
    bottom: 0.8rem;
    right: 0.8rem;
    background-color: rgba($zst_gray_darker, 0.3);
    padding: 0.5rem;
    border-radius: 6px;

    .durationText {
        font-weight: bold;
        font-size: 0.75rem;
    }
}

.title {
    padding: 1rem 0;
}

.fallbackTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    width: 80%;
    font-size: 1.25rem;
}

.time {
    position: absolute;
    right: 0.5rem;
    bottom: 0.75rem;
    padding: 0.3rem 0.5rem;
    background-color: #2f2f2f80;
}

.progress-container {
    position: absolute;
    bottom: 0;
    height: 5px;
    width: 100%;
    background: #b6b6ba;
    margin-top: 0.8rem;
}

.progress-bar {
    height: 5px;
    width: 100%;
    background: $zst_primary;
}
