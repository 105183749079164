@import '../../../styling/helpers';

.root {
    @include theme('color', 'text');

    margin: 0;
    font-size: 16px;
    font-weight: 300;
}

.bold {
    font-weight: bold;
}

.color-gray-black {
    color: $zst_gray_black;
}

.color-gray-darker {
    color: $zst_gray_darker;
}

.color-gray-dark {
    color: $zst_gray_dark;
}

.color-gray-medium {
    color: $zst_gray_medium;
}

.color-gray {
    color: $zst_gray;
}

.color-gray-light {
    color: $zst_gray_light;
}

.color-gray-white {
    color: $zst_gray_white;
}

.color-primary {
    color: $zst_primary;
}

.color-secondary {
    color: $zst_secondary;
}

.color-error {
    color: $zst_error;
}

.color-white {
    color: $zst_white;
}

.color-black {
    color: $zst_black;
}

.color-bg {
    color: $zst_bg;
}
