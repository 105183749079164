@import './styling/helpers.scss';
@import '~normalize.css/normalize.css';

// *,
// *::before,
// *::after {
//     box-sizing: border-box;
//     letter-spacing: 0.005rem;
// }

html {
    @include theme('font', 'font_body_1');
    @include theme('background', 'background');
    @include theme('color', 'text');

    height: 100%;
    font-size: 16px;
    overflow: hidden;
}

body {
    @include theme('color', 'text');

    font-family: interstate, sans-serif;
    font-size: 1rem;
    height: 100%;
    line-height: 1.5;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p, span {
    margin: 0;
    padding: 0;
}

a {
    @include theme('color', 'text');

    text-decoration: none;

    &:hover {
        color: $zst_primary;
    }
}
