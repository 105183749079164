@import '../../../styling/helpers';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 1px solid rgba($zst_gray_white, 0.2);
    border-radius: 4px;

    &:hover {
        cursor: pointer;
        border: 1px solid $zst_primary;
    }

    &.active {
        border: 1px solid $zst_primary;
    }
}

.logo {
    margin-bottom: 0.6rem;
}

.name {
    text-align: center;
}

.fallbackTitle {
    text-align: center;
    padding: 1rem 0;
}
