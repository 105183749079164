.background {
    position: absolute;
    z-index: -1;
    top: 0rem;
    left: 0;
    height: 900px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgb(38 39 39 / 60%), rgb(38 39 39)),
        url('/pricing-background.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
